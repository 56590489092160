<template>
  <div class="bm_example_list">
    <Introduce
      :moduleId="15"
      :data="{
        title: data.mainTitle,
        desc: data.viceTitle,
        picture: data.pictureUrl
      }"
    />
    <div class="bm_example_box js_bm_example_list">
      <ul class="bm_example_ul" v-if="list.length > 0">
        <li class="bm_example_every" v-for="item in list" :key="item.moduleId">
          <a
            :href="
              `/benchmarkExample/story?id=${item.moduleId}&moduleType=${
                modules.story.moduleType
              }&name=${encodeURIComponent(encodeURIComponent(item.articleTitle))}`
            "
            target="_blank"
            class="bm_exa_link"
            :class="[isResize && 'vishide animate__animated']"
          >
            <div class="bm_exa_img_box">
              <img
                class="bm_exa_img"
                :src="utils.setOssUrl(item.pictureCover)"
                alt=""
              />
              <p class="bm_exa_title" :title="item.articleTitle">
                {{ item.articleTitle }}
              </p>
            </div>
            <div v-if="isResize" class="bm_exa_details_box">
              <p class="bm_exa_desc" :title="item.configDigest">
                {{ item.configDigest }}
              </p>
              <p class="bm_exa_btn">
                了解更多 <i class="font_family icon-common_arrow_right"></i>
              </p>
            </div>
          </a>
        </li>
      </ul>
      <Nothing v-else class="nothing" />
    </div>
    <div class="bm_example_btn">
      <a v-if="!isCurPageTotal" class="more" @click="handlePages">加载更多</a>
    </div>
    <!--免费试用-->
    <FreeBenchmark v-if="isResize" />
  </div>
</template>

<script>
import { getSupplierInfoList } from '@/api/product.js'
import module from '@/const/module'
import $ from 'jquery'
import utils from '@/utils/utils'
import metaInfoMixins from '@/utils/mixin/metaInfoMixins'
import FreeBenchmark from '../product/components/freeBenchmark'

export default {
  mixins: [metaInfoMixins],
  components: { FreeBenchmark },
  data() {
    return {
      modules: {
        example: module.benchmarkExample,
        story: module.benchmarkStory
      },
      utils,
      // 总数据
      data: {},
      // 当前分页数据
      list: [],
      // 总数据
      listAll: [],
      top: 0,
      isResize: true,
      page: {
        pageNo: 0,
        pageSize: 6,
        total: 0
      }
    }
  },
  computed: {
    // 当前分页数量
    curPageTotal() {
      const data = this.page.pageNo * this.page.pageSize
      return data
    },
    // 前端分页数量比较
    isCurPageTotal() {
      const data = this.curPageTotal >= this.page.total
      return data
    }
  },
  created() {
    this.getSupplierInfoList()
  },
  mounted() {
    this.getResize()
    this.getListTop()
    this.$nextTick(() => {
      this.handleScroll()
      window.addEventListener('scroll', this.handleScroll)
      window.onresize = () => {
        this.getResize()
        this.removeVishide()
      }
    })
  },
  methods: {
    /**
     * 屏幕大小判断
     */
    getResize() {
      const width = window.innerWidth
      this.isResize = width > 960
    },
    getListTop() {
      this.$nextTick(() => {
        const el = $('.js_bm_example_list')
        let top = el.offset().top
        this.top = top
        window.onresize = () => {
          let top = el.offset().top
          this.top = top
        }
      })
    },
    /**
     * 获取标杆案例数据
     */
    getSupplierInfoList() {
      const params = {
        moduleType: this.modules.example.moduleType
      }
      getSupplierInfoList(params).then((res) => {
        if (res.success) {
          this.data = res.data
          this.listAll = res.data.supplierConfigResList
          this.page.total = res.data.supplierConfigResTotal
          this.handlePages()
        }
      })
    },
    /**
     * 前端分页事件
     */
    handlePages() {
      if (this.isCurPageTotal) return
      this.page.pageNo++
      this.list = this.isCurPageTotal
        ? this.listAll
        : this.listAll.filter((v, i) => i < this.curPageTotal)
      this.$nextTick(() => {
        this.handleScroll()
        this.removeVishide()
      })
    },
    handleScroll() {
      utils.handleScroll(
        $('.js_bm_example_list .bm_example_every'),
        (i, el) => {
          setTimeout(() => {
            el.children('.bm_exa_link')
              .removeClass('vishide')
              .addClass('animate__slideInUp')
              .removeClass('animate__slideOutDown')
          }, i * 100)
        },
        (i, el) => {
          setTimeout(() => {
            el.children('.bm_exa_link')
              .removeClass('animate__slideInUp')
              .addClass('animate__slideOutDown')
          }, i * 100)
        }
      )
    },
    removeVishide() {
      let w = $(window).width()
      if (w < 960) {
        $('.js_bm_example_list .bm_exa_link').removeClass('vishide')
      } else {
        $('.js_bm_example_list .bm_exa_link').addClass('vishide')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.bm_example_list {
  background-color: #fff;
  .bm_example_box {
    padding: 24px;
    width: 100%;
    margin: 0 auto;
    .bm_example_ul {
      display: flex;
      flex-wrap: wrap;
      .bm_example_every {
        margin-top: 24px;
        width: calc(50% - 14px);
        height: 266px;
        margin-left: 24px;
        transition: 0.7s;
        color: #000;
        border-radius: 16px;
        border: 1px solid #e6e6e6;
        overflow: hidden;
        &:first-child {
          margin-top: 0;
        }
        &:nth-child(2) {
          margin-top: 0;
        }
        &:nth-child(2n + 1) {
          margin-left: 0;
        }
        .bm_exa_link {
          display: inline-block;
          width: 100%;
          height: 100%;
          color: #000;
        }
        .bm_exa_img_box {
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;
        }
        .bm_exa_img {
          width: 100%;
          height: calc(100% - 70px);
        }
        .bm_exa_title {
          width: 100%;
          height: 70px;
          font-size: 28px;
          padding: 16px;
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .bm_exa_desc {
          font-size: 20px;
          font-weight: 500;
          line-height: 32px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          word-break: break-all;
        }
        .bm_exa_btn {
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          padding: 24px 0;
        }
      }
    }
  }
  .bm_example_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 36px 0 60px;
    text-align: center;
    .more {
      font-size: 24px;
      font-weight: 400;
      color: #999;
      cursor: pointer;
    }
  }
}
@media (min-width: 751px) and (max-width: 960px) {
}

@media (min-width: 961px) {
  .bm_example_list {
    .bm_example_box {
      padding: 60px 30px;
      width: 1200px;
      .bm_example_ul {
        .bm_example_every {
          height: 331px;
          color: #fff;
          border-radius: 8px;
          border: none;
          &:hover {
            box-shadow: 0px 20px 40px 0px rgba(62, 74, 101, 0.2);
            .bm_exa_details_box {
              bottom: 0;
            }
          }
          .bm_exa_link {
            color: #fff;
          }
          .bm_exa_img {
            width: 100%;
            height: 100%;
          }
          .bm_exa_title {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 64px;
            font-size: 28px;
            padding: 14px 16px;
            background: rgba(0, 0, 0, 0.65);
            line-height: 36px;
            font-weight: 600;
            z-index: 1;
          }
          .bm_exa_details_box {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            position: absolute;
            bottom: -100%;
            z-index: 2;
            width: 100%;
            height: 100%;
            background: rgba(0, 82, 231, 0.65);
            backdrop-filter: blur(5px);
            padding: 0 24px;
            transition: 1s;
          }
        }
      }
    }
    .bm_example_btn {
      padding: 0 0 60px;
      .more {
        font-size: 16px;
        font-weight: 400;
        color: #0064eb;
        cursor: pointer;
      }
    }
  }
}
</style>
